/* customStyles.css */

.authenticator {
    background-color: #f7f7f7; /* Change the background color */
    padding: 20px; /* Add some padding */
  }
  
  .authenticator h2 {
    color: #333; /* Change the header color */
  }
  
  .authenticator .form-field {
    margin-bottom: 10px; /* Space between fields */
  }
  
  /* Add more custom styles as needed */
  