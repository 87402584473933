.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  padding: 15%;
  max-width: 100%;
  width: auto;
}



.App-header {
  background-color: #e7e7e7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(54, 54, 141);
}

.Nav {
  background-color: #d3d3d3;
}

.Nav-logo {
  position: absolute;
  top: 10px; /* Adjust based on your navbar's padding or margin */
  left: 10px; /* Adjust based on your navbar's padding or margin */
  height: 50px; /* Adjust based on your design requirements */
  width: auto; /* Maintains aspect ratio */
 }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* customStyles.css */
.authenticator-container {
  background-color: #f5f5f5; /* Light background for the authenticator */
  height: 100vh; /* Full height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form {
  max-width: 400px; /* Set a max width */
  padding: 20px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center; /* Center text */
}

.auth-form img {
  max-width: 80px; /* Logo size */
  margin-bottom: 20px;
}

.auth-form h5 {
  margin-bottom: 20px; /* Space below title */
}

